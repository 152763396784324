<template>
  <UserForm v-if="user" :user="user" :isEdit="false"/>
</template>

<script>

import UserForm from "@/modules/Admin/Components/UserForm.vue";

export default {
  name: "CreateUserPage",
  components: {UserForm},

  data() {
    return {
      user: {
        username: '',
        email: '',
        type: 'admin',
        can_impersonate: false,
        language: 'en',
        timezone: 'Europe/Madrid',
        scoped_roles: []
      },
    }
  },
};
</script>
